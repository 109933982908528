.dividerCalendarPermission {
  margin-top: 24px !important;
  margin-bottom: 8px !important;
  width: 100%;
}

.selectDateContainer {
  align-items: flex-start;
}

.dateContainer {
  align-items: flex-start;
  width: 600px;
}

.startEndDateContainer {
  flex-direction: row !important;
  gap: 8px;
  margin-top: 10px;
}

.dateFooter {
  font-size: 14px !important;
  margin-bottom: 16px !important;
}

.entriesContainer {
  max-width: 75%;
  margin: auto;
}

.entriesHeader {
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-top: 40px !important;
}

.calendarBusinessDetailsContainer {
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}

.imageHeader {
  flex-direction: row !important;
  gap: 11px;
}

.buttonHeader {
  flex-direction: row !important;
  gap: 3px;
}

.inputCheckBox {
  width: 24px;
  height: 24px;
  border-radius: 5px;
}

.readyToSyncHeader {
  margin-top: 6px;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.imageFilter {
  width: 16px;
  margin-left: 12px;
}

.hoverTable tr:hover {
  background-color: #eef6fc;
}

.tableRowHeader {
  border-bottom: 1px solid #ccd1d9;
  padding: 12px 0;
}

.tableHeaderFirstData {
  padding: 0px 14px 0px 8px;
  width: 5%;
}

.tableHeaderSecondData {
  width: 25%;
  padding: 12px 0;
}

.tableHeaderClient {
  width: 70%;
}

.tableBodyFirstData {
  padding: 0px 14px 0px 8px;
}

.tableHeaderTime {
  padding-right: 8px;
  text-align: right;
  position: relative;
}

.tableBodySecondData {
  padding: 8px 8px;
}
.tableBodyThirdData {
  padding: 8px 0px;
  overflow-wrap: anywhere;
}

.teamDataEdit {
  width: 200px;
}

.modalContainer {
  min-width: 528px !important;
}

.modalOkBtn {
  margin-right: 8px !important;
}

.editDeleteBtnContainer {
  position: absolute;
  top: -13px;
  background-color: white;
  border: 2px solid #7f8c9f;
  border-radius: 26px;
  height: 20px;
}

.modalBtnContainer {
  margin-bottom: 6px;
}

.editItemContainer {
  flex-direction: row !important;
  padding: 8px 8px;
  gap: 8px;
  background-color: white;
}

.actionContainer {
  flex-direction: row !important;
}

.checkButton {
  height: 100% !important;
  min-width: unset !important;
  max-width: 40px !important;
}

.closeButton {
  height: 100% !important;
  min-width: unset !important;
  max-width: 10px !important;
}

.verticalDivider {
  background-color: #7f8c9f;
  height: 50px !important;
  width: 1px !important;
}

.paginationFooter {
  flex-direction: row !important;
  justify-content: space-between;
  margin-top: 8px !important;
  margin-bottom: 12px !important;
}

.rowsDropdownPagination {
  min-width: 160px !important;
}

.rowsDropdownPaginationContainer {
  flex-direction: row !important;
  gap: 8px;
  align-items: center;
}

.dropdownBorder {
  border: 2px solid #7f8c9f !important;
  border-radius: 5px !important;
}

.footerHours {
  flex-direction: row !important;
  align-items: end !important;
  justify-content: end;
  background: #f2f3f5;
  padding: 24px 8px 24px 0px;
}

.totalHourFooter {
  flex-direction: row !important;
}
.totalTimeFooter {
  margin-right: 16px !important;
}

.datePickerEdit {
  flex-direction: row-reverse !important;
}

.loading {
  display: flex;
  justify-content: center !important;
}

.durationHour {
  white-space: nowrap;
}

.noClientText {
  font-style: italic;
}

.noClientText::after {
  content: "*";
  color: red;
}

.incompleteText {
  border-radius: 5px;
  padding: 2px 8px 2px 8px;
  background-color: #ccd1d9;
}

.SyncCalendarEventsButton {
  padding: 8px 36px !important;
}

.selectBusinessAndCalendarFooter {
  width: 95%;
}

.clientCardOptions {
  flex-direction: row !important;
  align-items: center;
  gap: 10px;
}

.SyncEventsGridContent {
  text-align: center;
  align-items: center;
}

.calendarIcon:hover {
  cursor: pointer !important;
}

.calendarCheckBoxContainer {
  width: 75%;
}

.syncFbInvoiceBtn {
  flex-direction: row !important;
  gap: 24px !important;
}

.fbIncvoiceBtnAndIcon {
  flex-direction: row !important;
  gap: 3px !important;
  align-items: center !important;
}

.fbInvoiceBtn {
  padding: 8px 16px 8px 8px !important;
}

.privacyPolicy {
  padding: 0px 40px;
  margin: 0px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fffefd;
  border: 2px solid rgba(6, 40, 65, 0.20287);
  box-shadow: 4px 4px 0px rgba(6, 41, 66, 0.0710202);
  border-radius: 5px;
}
