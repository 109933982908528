@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-Semibold.eot");
  src: url("./assests/fonts/FoundersGrotesk-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-Semibold.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-Semibold.svg#FoundersGrotesk-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-Light.eot");
  src: url("./assests/fonts/FoundersGrotesk-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-Light.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-Light.svg#FoundersGrotesk-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-MediumItalic.eot");
  src: url("./assests/fonts/FoundersGrotesk-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-MediumItalic.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-MediumItalic.svg#FoundersGrotesk-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-Bold.eot");
  src: url("./assests/fonts/FoundersGrotesk-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-Bold.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-Bold.svg#FoundersGrotesk-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-RegularItalic.eot");
  src: url("./assests/fonts/FoundersGrotesk-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-RegularItalic.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-RegularItalic.svg#FoundersGrotesk-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-Regular.eot");
  src: url("./assests/fonts/FoundersGrotesk-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-Regular.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-Regular.svg#FoundersGrotesk-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-LightItalic.eot");
  src: url("./assests/fonts/FoundersGrotesk-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-LightItalic.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-LightItalic.svg#FoundersGrotesk-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-SemiboldItalic.eot");
  src: url("./assests/fonts/FoundersGrotesk-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-SemiboldItalic.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-SemiboldItalic.svg#FoundersGrotesk-SemiboldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-Medium.eot");
  src: url("./assests/fonts/FoundersGrotesk-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-Medium.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-Medium.svg#FoundersGrotesk-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("./assests/fonts/FoundersGrotesk-BoldItalic.eot");
  src: url("./assests/fonts/FoundersGrotesk-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/FoundersGrotesk-BoldItalic.ttf") format("truetype"),
    url("./assests/fonts/FoundersGrotesk-BoldItalic.svg#FoundersGrotesk-BoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
